import React from 'react';
import ServiceTemplate from './OurServices/ServiceTemplate';
import Img1 from '../Assets/Service/Group 846.png';
import Img2 from '../Assets/Service/Group 1022.png';
import Logo1 from '../Assets/Service/image 5.png';
// import Logo2 from '../Assets/Service/image 3.png'
import YoutubeThumbnail from '../Assets/Service/Rectangle 31.png';

const SeaFreight = () => {
  const data = {
    title: 'Sea Freight',
    description:
      'Efficient Sea Freight Solutions: Connecting Global Destinations with Ease',
    portraitImage: Img1,
    landscapeImage: Img2,
    landscapeImageDescription: (
      <>
        At EFS LOGISTICS, we take pride in being a prominent player in the sea
        freight industry. With direct consolidations to and from various
        destinations and origins worldwide, we provide comprehensive solutions
        for all types of sea freight shipments. Whether it's full container load
        (FCL), less than container load (LCL), break-bulk, or roll-on/roll-off
        (RORO) cargo, we have you covered. <br /> <br />
        Our extensive network of consol partners in every region allows us to
        offer global coverage at competitive rates. By leveraging these
        partnerships, we ensure that your shipments are transported seamlessly,
        efficiently, and at minimal cost.
        <br /> <br />
        As a licensed forwarder authorized by the Transport General Authority of
        Saudi Arabia, you can trust us to handle your sea freight operations
        with the highest level of professionalism and compliance. <br /> <br />
        Choose EFS LOGISTICS for reliable sea freight services that connect you
        to the world, delivering your cargo safely and on time.
      </>
    ),
    logos: [Logo1],
    youtubeLink: '',
    youtubeThumbnailImage: YoutubeThumbnail,
    buttonText: 'EFS Sea Freight Services',
  };
  return (
    <ServiceTemplate
      title={data.title}
      description={data.description}
      portraitImage={data.portraitImage}
      landscapeImage={data.landscapeImage}
      landscapeImageDescription={data.landscapeImageDescription}
      logos={data.logos}
      youtubeLink={data.youtubeLink}
      youtubeThumbnailImage={data.youtubeThumbnailImage}
      buttonText={data.buttonText}
    />
  );
};

export function Head() {
  return <title>Sea Freight | EFS Logistics KSA</title>;
}
export default SeaFreight;
